<template>
  <div class="coaches-report fixed-draggable-dynamic-table-wrapper-height semi-box-border-y">

    <draggable-dynamic-table ref="coachReportTable"
                             :columns="columnsLabel"
                             :options="options"
                             @column:select="consoleLog($event)"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @load:more="getCoachesReport()"
                             v-model="data"/>

    <button id="yesterday" v-show="false" @click="setDateFilters('yesterday')"></button>
    <button id="week" v-show="false" @click="setDateFilters('week')"></button>
    <button id="month" v-show="false" @click="setDateFilters('month')"></button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from 'moment-jalaali'
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import {
  addComma,
  checkUserPermissions,
  getAvatarUrl,
  getStaticFileUrl,
  getTimeFromServer
} from '../../../../assets/js/functions'
import CustomIcon from '../../../../components/customIcon/customIcon'
import {getCoachReport} from "@/http/requests/reports";

export default {
  name: 'usersList',
  metaInfo () {
    return {
      title: this.$t('report.coach.title')
    }
  },
  components: {
    CustomIcon,
    DraggableDynamicTable
  },
  props: {
    roleId: 0
  },
  data () {
    return {
      requestSent: false,
      insertNewCoachPromptStatus: false,
      showCoachPromptStatus: false,
      editCoachPromptStatus: false,
      loadingTimer: 0,
      options: {
        id: 'coachReportTable',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'assets',
          i18n: 'report.coach.table.header.assets',
          width: 'calc((100% / 4))',
          minWidth: 150,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            }
          ],
          footer: {}
        },
        {
          field: 'cost',
          i18n: 'report.coach.table.header.cost',
          width: 'calc((100% / 4))',
          minWidth: 150,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            }
          ],
          footer: {}
        },
        {
          field: 'name',
          i18n: 'report.coach.table.header.name',
          width: 'calc((100% / 3))',
          minWidth: 200,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'image',
          i18n: 'report.coach.table.header.image',
          // locked: true,
          width: '90px',
          minWidth: 90,
          align: 'center'
        },
        {
          field: 'row',
          i18n: 'report.coach.table.header.row',
          width: '50px',
          minWidth: 50,
          align: 'center',
          // locked: true,
          // sortable: true,
          footer: {
            /*type: 'auto-counter'*/
          }
        }
      ],
      data: [],
      filters: [],
      sorts: ['order[0]=created_at,desc'],
      currentTime: '',
      filterStatus: 'date',
      dateFilter: [],
      selectedCoach: {},
      page: 1,
      endedList: false,
      actions: [
        {
          toolbar: [
            {
              type: 'date',
              range: true,
              active: true
              // permission: 'court.create'
            },
            {
              id: 'yesterday',
              i18n: 'report.coach.labels.yesterday',
              style: 'margin: 0 7px;',
              color: 'primary',
              active: false
            },
            {
              id: 'week',
              i18n: 'report.coach.labels.week',
              style: 'margin: 0 7px;',
              color: 'primary',
              active: false
            },
            {
              id: 'month',
              i18n: 'report.coach.labels.month',
              style: 'margin: 0 7px;',
              color: 'primary',
              active: false
            },
          ],
          leftToolbar: [
            {
              id: 'printTable',
              // i18n: 'draggableDynamicTable.actions.print',
              icon: 'icon-printer',
              iconPack: 'feather'
            },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'icon-download',
              iconPack: 'feather'
            },
            // {
            //   id: {name: 'customersTrash'},
            //   type: 'link',
            //   icon: 'icon-trash',
            //   iconPack: 'feather',
            //   permission: 'user.delete'
            // },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile'
    })
  },
  mounted () {
    this.$nextTick(() => {
      this.currentTime = moment(getTimeFromServer()).format(this.$validator('moment.date'))
      const currentMoment = moment(this.currentTime, this.$validator('moment.date'))
      const day = currentMoment.format(this.$validator('moment.day'))
      this.dateFilter = [currentMoment.subtract(parseInt(day) - 1, "day").format(this.$validator('moment.date')), this.currentTime]
      this.$store.dispatch('helper/changeCalendarDate', this.dateFilter)

      if (this.isMobile) {
        this.actions[0].toolbar.splice(1, 3)
        this.actions[0].toolbar[0].active = false
      }
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      }, 50)

      this.getCoachesReport()
    })
  },
  methods: {
    getCoachesReport () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.coachReportTable && this.data.length === 0) {
              this.$refs.coachReportTable.loadMoreStatus = 'FirstLoad'
            } else if (this.$refs.coachReportTable && this.data.length > 0) {
              this.$refs.coachReportTable.loadMoreStatus = 'Loading'
            }

            getCoachReport(this.page, this.filters, this.sorts).then((res) => {
              this.endedList = res.data.data.length === 0
              const coaches = res.data.data
              coaches.forEach((coach) => {

                this.data.push({
                  row: this.data.length + 1,
                  id: coach.id,
                  registryDate: coach.created_at,
                  name: coach.user.name || coach.user.family ? `${ coach.user.name || '' } ${  coach.user.family || '' }` : this.$t('users.user.labels.unknown'),
                  cost: {
                    type: 'price',
                    value: Math.abs(coach.total_cost) || 0
                  },
                  assets: {
                    type: 'price',
                    value: Math.abs(coach.total_revenue) || 0
                  },
                  image: {
                    type: 'img',
                    src: coach.user.avatar ? getAvatarUrl(coach.user.avatar) : coach.user.gender === 1 ? getStaticFileUrl('/images/man-avatar.png') : getStaticFileUrl('/images/woman-avatar.png'),
                    class: 'coach-avatar'
                  },
                })
              })

              this.page = res.data.pagination.current_page + 1
              if (res.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('row')
                this.columnsLabel[row_index].footer.value = res.data.pagination.total
              }

              const revenue_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('assets')
              if (revenue_index > -1) {
                this.columnsLabel[revenue_index].footer.value = addComma(res.data.extra.total.revenue, true)
              }

              const cost_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('cost')
              if (cost_index > -1) {
                this.columnsLabel[cost_index].footer.value = addComma(res.data.extra.total.cost, true)
              }

              if (this.$refs.coachReportTable) this.$refs.coachReportTable.loadMoreStatus = ''

              this.requestSent = false
            })
              .catch((error) => {
                if (this.$refs.coachReportTable) this.$refs.coachReportTable.loadMoreStatus = 'Danger'

                this.requestSent = false
              })
          } else {

            this.requestSent = false
          }
        }, 400)
      }
    },
    showCoach (coach) {
      this.selectedCoach = coach
      this.showCoachPromptStatus = true
    },
    setDateFilters (type) {
      let currentMoment = moment(getTimeFromServer())
      let dateFilterIndex = -1
      this.filters.forEach((filter) => {
        if (filter.startsWith('start_range=')) {
          dateFilterIndex = this.filters.indexOf(filter)
        }
      })
      if (dateFilterIndex > -1) {
        this.filters.splice(dateFilterIndex, 2)
      }
      this.data = []
      this.page = 1
      this.endedList = false

      let actions = JSON.parse(JSON.stringify(this.actions[0]))
      actions.toolbar = actions.toolbar.map(elm => {return {...elm, active: false}})

      switch (type) {
        case 'date':
          if (this.dateFilter.length > 0) {
            this.filterStatus = 'date'
            actions.toolbar[0].active = true
            let filter = {}
            if (Array.isArray(this.dateFilter)) {
              filter = {
                start_range: this.dateFilter[0],
                end_range: this.dateFilter[1] || this.dateFilter[0]
              }
            } else {
              filter = {
                start_range: this.dateFilter,
                end_range: this.dateFilter
              }
            }
            this.filters.push(`start_range=${filter.start_range}`)
            this.filters.push(`end_range=${filter.end_range}`)
          }
          break

        case 'today':
          this.filterStatus = 'today'
          this.dateFilter = []
          this.filters.push(`start_range=${this.currentTime}`)
          this.filters.push(`end_range=${this.currentTime}`)
          break

        case 'yesterday':
          this.filterStatus = 'yesterday'
          actions.toolbar[1].active = true
          this.dateFilter = []
          const yesterday = currentMoment.subtract(1, 'day').format(this.$validator('moment.date'))
          this.filters.push(`start_range=${yesterday}`)
          this.filters.push(`end_range=${yesterday}`)
          break

        case 'week':
          let week = parseInt(currentMoment.subtract(1, 'week').format(this.$validator('moment.weekDayIndex')))
          if (this.$store.state.setting.clubInfo.club_locale === 'IRI') {
            week += 1
          }
          const weekStartDate = currentMoment.subtract(parseInt(week) - 1, "day").format(this.$validator('moment.date'))
          const weekEndDate = moment(weekStartDate, this.$validator('moment.date')).add(6, 'day').format(this.$validator('moment.date'))
          this.filterStatus = 'week'
          actions.toolbar[2].active = true
          this.dateFilter = []
          this.filters.push(`start_range=${weekStartDate}`)
          this.filters.push(`end_range=${weekEndDate}`)
          break

        case 'month':
          currentMoment = moment(this.currentTime, this.$validator('moment.date'))
          let monthStartDate = currentMoment.subtract(1, 'month').startOf('jMonth').format(this.$validator('moment.date'))
          let monthEndDate = moment(monthStartDate, this.$validator('moment.date')).endOf('jMonth').format(this.$validator('moment.date'))
          this.filterStatus = 'month'
          actions.toolbar[3].active = true
          this.dateFilter = []
          this.filters.push(`start_range=${monthStartDate}`)
          this.filters.push(`end_range=${monthEndDate}`)
          break
      }
      if (!this.isMobile) {
        this.$nextTick(() => {
          this.$store.dispatch('updateNavbarActions', actions)
        })
      }

      this.getCoachesReport()
    },
    setFilters (filters) {
      const filters_list = []
      Object.keys(filters).forEach((key) => {
        switch (key) {

        case 'name':
          if (filters[key].search !== '') filters_list.push(`user=${  filters[key].search}`)
          break

        case 'phoneNumber':
          if (filters[key].search !== '') filters_list.push(`phone_number=${  filters[key].search}`)
          break

        case 'cost':
          if (filters[key].search !== '') filters_list.push(`total_cost=${filters[key].search},${filters[key].type.id}`)
          break

        case 'assets':
          if (filters[key].search !== '') filters_list.push(`total_revenue=${filters[key].search},${filters[key].type.id}`)
          break
        }
      })

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list

      this.setDateFilters(this.filterStatus)
    },
    setSort (columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

        case 'row':
          sorts_list.push(`order[0]=id,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'name':
          sorts_list.push(`order[0]=user,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'phoneNumber':
          sorts_list.push(`order[0]=phone_number,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'cost':
          sorts_list.push(`order[0]=total_cost,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'assets':
          sorts_list.push(`order[0]=total_revenue,${  columns[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      if (!sorts_list.length) sorts_list.push('order[0]=created_at,desc')

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.getCoachesReport()
    },
    consoleLog () {
      // console.log(event)
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  },
  watch: {
    '$store.state.helper.calendarDate': {
      handler (val) {
        this.data = []
        this.page = 1
        this.endedList = false

        let dateFilterIndex = -1
        this.filters.forEach((filter) => {
          if (filter.startsWith('start_range=')) {
            dateFilterIndex = this.filters.indexOf(filter)
          }
        })
        this.filters.splice(dateFilterIndex, 2)
        if (typeof val === 'object' && val.length > 1) {
          this.filters.push(`start_range=${val[0]}`)
          this.filters.push(`end_range=${val[1]}`)
        } else {
          this.filters.push(`start_range=${val}`)
          this.filters.push(`end_range=${val}`)
        }
        this.getCoachesReport()
      }
    }
  }
}
</script>

<style lang="scss">
  .coaches-report {
    .draggable-dynamic-table {
      .coach-avatar {
        height: 50px;
        width: 50px;
        object-fit: cover;
      }
    }
  }
</style>
